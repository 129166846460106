import {FormControl, InputLabel, MenuItem} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import RemoveIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Button, Text} from '@therapie-ui';
import {BaseItem, Column, Table} from '@therapie-ui/Table/Table';
import {
  CARD_PAYMENT_TYPE_CODE,
  PAYMENT_TYPES_NAMES,
  VIRTUAL_TERMINAL_TYPE_CODE,
  VOUCHER_PAYMENT_TYPE_CODE,
  KLARNA_PAYMENT_TYPE_CODE
} from 'constants/payments';
import {useCurrency} from 'hooks/currency/useCurrency'; 
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {purchaseItemsState} from 'state/atoms/purchase/purchaseItems';
import {defaultPaymentInstancesSelector} from 'state/selectors/purchase';
import styled from 'styled-components';
import {StateSetter} from 'types/common';
import {PaymentTypeModel} from 'types/DerivedApiModel';
import {notNil} from 'utils/general';
import {usePaymentTypes} from '../hooks';
import {useAutoAddNuaVoucher} from '../hooks/useAutoAddNuaVoucher';
import {IUsePaymentAmountsCalculations} from '../hooks/usePaymentAmountsCalculations';
import {usePurchaseFormikContext} from '../hooks/usePurchaseFormikContext';
import {
  fromPaymentListTypeToPaymentTypeInstance,
  fromPaymentTypeInstanceToPaymentTypeTableItem
} from '../utils/mappers';
import {PaymentTableInputCell} from './PaymentTableInputCell';
import { klarnaAmount } from '../Dialogs/PayWithKlarnaDialog';
import { setKlarnaAvailability } from '../hooks/setKlarnaAvailability';
import { setKlarnaPaymentTypeId } from '../hooks/setKlarnaPaymentTypeId';



interface Props extends Pick<IUsePaymentAmountsCalculations, 'amountOfPaymentDueAfterDiscounts'> {
  isFormDisabled: boolean;
  isCalculationLoading: boolean;
  setSearchClientVoucherDialogOpen: StateSetter<boolean>;
  setPayWithKlarnaDialogOpen: StateSetter<boolean>;
  setVoucherAsPaymentType: StateSetter<PaymentTypeModel | undefined>;
  setKlarnaAsPaymentType: StateSetter<PaymentTypeModel | undefined>;
}

var klarnaPaymentTypeId: string;
var disableForm: boolean;

export const PaymentTypesSection: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({
    isFormDisabled,
    amountOfPaymentDueAfterDiscounts,
    isCalculationLoading,
    setSearchClientVoucherDialogOpen,
    setPayWithKlarnaDialogOpen,
    setVoucherAsPaymentType,
    setKlarnaAsPaymentType
  }) => {
    const [addPaymentTypeButtonHasFocus, setAddPaymentTypeButtonHasFocus] = useState<boolean>(false);
    const [expanded, setExpanded] = useState(true);
    const purchaseItems = useRecoilValue(purchaseItemsState);
    const {currencySymbol} = useCurrency();
    const {
      list: paymentTypeList,
      instances: paymentTypeInstances,
      updateInstances,
      loading: loadingPaymentTypes,
      voucherPaymentType,
      klarnaPaymentType
    } = usePaymentTypes();
    const defaultPaymentInstances = useRecoilValue(defaultPaymentInstancesSelector);
    const {paymentTypeInstancesErrors} = usePurchaseFormikContext();
    const {nuaVoucherInstances, loadingClientVouchers} = useAutoAddNuaVoucher();
    const voucherPaymentTypeFoundInClinic = !!voucherPaymentType;
    const klarnaPaymentTypeFoundInClinic = !!klarnaPaymentType
    const hasPurchaseItems = purchaseItems.length > 0;
    const isLoading = loadingPaymentTypes || loadingClientVouchers;

    const paymentTypeInstanceTableItems: PaymentTypeTableItem[] = useMemo(
      () =>
        paymentTypeInstances.filter(notNil).map((instance, index) =>
          fromPaymentTypeInstanceToPaymentTypeTableItem({
            instance,
            currencySymbol,
            error: paymentTypeInstancesErrors?.[index]
          })
        ),
      [paymentTypeInstances, currencySymbol, paymentTypeInstancesErrors]
    );

    const paymentTypeOptions = useMemo(
      () =>
        paymentTypeList
          ?.filter(type => {
            const isInstanceAlreadyAdded = paymentTypeInstances.some(
              ({paymentTypeId}) => paymentTypeId === type.paymentTypeId
            );
            // Manage which payment types can be added more than once
            // Default is only one allowed - add a case for any you want multiple of.
            switch (type.code) {
              // Can be used unlimited times
              case VIRTUAL_TERMINAL_TYPE_CODE:
              case CARD_PAYMENT_TYPE_CODE:
              case VOUCHER_PAYMENT_TYPE_CODE:
                return true;

              // Everything else can only be used once
              default:
                return !isInstanceAlreadyAdded;
            }
          })
          // Note: this sorts the dropdown options, not the payment instances
          .sort(({name: a}, {name: b}) => {
            if (!a || !b) return 0;
            return a > b ? 1 : b > a ? -1 : 0;
          }),
      [paymentTypeList, paymentTypeInstances]
    );

    const {isCardInputDirty, isVirtualTerminalInputDirty} = useMemo(
      () => ({
        isVirtualTerminalInputDirty: !!paymentTypeInstances.find(
          el => el.name?.toUpperCase() === PAYMENT_TYPES_NAMES.VIRTUAL_TERMINAL
        )?.amount,
        isCardInputDirty: !!paymentTypeInstances.find(el => el.name?.toUpperCase() === (PAYMENT_TYPES_NAMES.CARD_PAYMENT) || el.name?.toUpperCase() === "CARD PAYMENT")
          ?.amount
      }),
      [paymentTypeInstances]
    );

    // Callbacks
    const updatePaymentTypeAmount = useCallback(
      (item: PaymentTypeTableItem, amount: string) => {
        const cleanAmount = Math.abs(Number(amount));
        updateInstances(
          paymentTypeInstances.map(instance =>
            instance.id === item.id ? {...instance, amount: cleanAmount} : instance
          )
        );
      },
      [paymentTypeInstances, updateInstances]
    );

    const updatePaymentTypeAmountWithAllIn = useCallback(
      (item: PaymentTypeTableItem) =>
        updateInstances(
          paymentTypeInstances.map(instance => {
            const allInAmountLimitedToVoucherBalanceIfAny = instance.voucherBalance
              ? Math.min(amountOfPaymentDueAfterDiscounts, instance.voucherBalance)
              : amountOfPaymentDueAfterDiscounts;
            return {
              ...instance,
              amount: instance.id === item.id ? allInAmountLimitedToVoucherBalanceIfAny : undefined
            };
          })
        ),
      [amountOfPaymentDueAfterDiscounts, paymentTypeInstances, updateInstances]
    );

    const resetPaymentTypeAmount = useCallback(
      (item: PaymentTypeTableItem) => {
        updateInstances(
          paymentTypeInstances.map(instance => (instance.id === item.id ? {...instance, amount: undefined} : instance))
        );
      },
      [paymentTypeInstances, updateInstances]
    );

    const removePaymentTypeFromTable = useCallback(
      (item: PaymentTypeTableItem) => updateInstances(paymentTypeInstances.filter(({id}) => id !== item.id)),
      [paymentTypeInstances, updateInstances]
    );

    const getInputDisableStatus = useCallback(
      (item: PaymentTypeTableItem): boolean =>
        isCalculationLoading ||
        (item.name?.toUpperCase() === PAYMENT_TYPES_NAMES.CARD_PAYMENT && isVirtualTerminalInputDirty) ||
        (item.name?.toUpperCase() === "CARD PAYMENT" && isVirtualTerminalInputDirty) ||
        (item.name?.toUpperCase() === PAYMENT_TYPES_NAMES.VIRTUAL_TERMINAL && isCardInputDirty) || 
        (item.name?.toUpperCase() === PAYMENT_TYPES_NAMES.KLARNA),
      [isCardInputDirty, isVirtualTerminalInputDirty, isCalculationLoading]
    );

    const addPaymentTypeToList = useCallback(
      (type: PaymentTypeModel) => {
        const paymentType = paymentTypeList.find(({paymentTypeId}) => paymentTypeId === type.paymentTypeId);
        if (!paymentType) return;
        const paymentInstance = fromPaymentListTypeToPaymentTypeInstance(paymentType);
        updateInstances(prevState => [...prevState, paymentInstance]);
      },
      [paymentTypeList, updateInstances]
    );

    function localKlarnaPaymentTypeId (paymentTypeId: string )

    {
     klarnaPaymentTypeId = paymentTypeId;
    }

    function getPaymentTypeName(name: string | null)
    {
      if (name == 'STRIPE DONT USE')
      {return 'Card Payment'}
      else
      {return name}
    }

    const handleSelectPaymentMethod = useCallback(
      (type: PaymentTypeModel) => {
        if ((type.code == KLARNA_PAYMENT_TYPE_CODE) && (setKlarnaAvailability())) {
          setKlarnaAsPaymentType(type);
          setPayWithKlarnaDialogOpen(true);
          var klarnaPaymentTypeId: string = setKlarnaPaymentTypeId();
          localKlarnaPaymentTypeId (klarnaPaymentTypeId);
          return;
        }
        else if ((type.code == KLARNA_PAYMENT_TYPE_CODE) && (!setKlarnaAvailability())){
          alert ("Klarna integration not available in clinic or Walk In client");
        }
        else if (type.code == (VOUCHER_PAYMENT_TYPE_CODE)) {
          setVoucherAsPaymentType(type);
          setSearchClientVoucherDialogOpen(true);
          return;
        }
        else {
          addPaymentTypeToList(type);
          return;
        }
      },
      [addPaymentTypeToList, setVoucherAsPaymentType, setKlarnaAsPaymentType, setSearchClientVoucherDialogOpen, setPayWithKlarnaDialogOpen]
    );

    // Effects

    useEffect(() => {
          disableForm = (klarnaAmount > 0);
          console.log (klarnaAmount);
    }, [klarnaAmount]);


    const renderCell = useCallback(
      (item: PaymentTypeTableItem, column: Column, rowIndex: number) => {
        if (item.name == 'STRIPE DONT USE')
          {item.name = 'Card Payment'}
       else
          {item.name = item.name}
        const paymentIndex = column.id as keyof PaymentTypeTableItem;
        const testUid = item.name?.split(' ')[0].toLowerCase();

        switch (column.id) {
          case 'amount':
            if (item.name != ("Klarna" || "KLARNA"))
              {
                return voucherPaymentTypeFoundInClinic && item.isVoucherPlaceholder ? (
                  <ApplyVoucherButton
                    variant="outlined"
                    onClick={() => handleSelectPaymentMethod(voucherPaymentType)}
                    disabled={!hasPurchaseItems}
                  >
                    Use Voucher
                  </ApplyVoucherButton>
                ) : (
                  <PaymentTableInputCell
                    amountOfPaymentDueAfterDiscounts={amountOfPaymentDueAfterDiscounts}
                    getInputDisableStatus={getInputDisableStatus}
                    isErrored={!!paymentTypeInstancesErrors?.[rowIndex]}
                    isFormDisabled={isFormDisabled || (disableForm)}
                    item={item}
                    rowIndex={rowIndex}
                    updatePaymentTypeAmount={updatePaymentTypeAmount}
                    updatePaymentTypeAmountWithAllIn={updatePaymentTypeAmountWithAllIn}
                    resetPaymentTypeAmount={resetPaymentTypeAmount}
                  />
                );}
                else{
                  return voucherPaymentTypeFoundInClinic && item.isVoucherPlaceholder ? (
                    <ApplyVoucherButton
                      variant="outlined"
                      onClick={() => handleSelectPaymentMethod(voucherPaymentType)}
                      disabled={!hasPurchaseItems}
                    >
                      Use Voucher
                    </ApplyVoucherButton>
                  ) : (
                    <PaymentTableInputCell
                      amountOfPaymentDueAfterDiscounts={amountOfPaymentDueAfterDiscounts}
                      getInputDisableStatus={getInputDisableStatus}
                      isErrored={!!paymentTypeInstancesErrors?.[rowIndex]}
                      isFormDisabled={true}
                      item={item}
                      rowIndex={rowIndex}
                      updatePaymentTypeAmount={updatePaymentTypeAmount}
                      updatePaymentTypeAmountWithAllIn={updatePaymentTypeAmountWithAllIn}
                      resetPaymentTypeAmount={resetPaymentTypeAmount}
                    />
                  );
                }
          case 'action':
            if (item.name != ("Klarna" || "KLARNA"))
              {
            return (
              <RemoveButtonContainer>
                <Button
                  iconRound
                  variant="ghost"
                  icon={<RemoveIcon />}
                  fullWidth
                  data-test-id={`btn-delete-${testUid}`}
                  onClick={() => removePaymentTypeFromTable(item)}
                />
              </RemoveButtonContainer>
            )}
            else
            {
              return (
                <RemoveButtonContainer>
                  <Button
                    iconRound
                    variant="ghost"
                    icon={<RemoveIcon />}
                    disabled={true}
                    fullWidth
                    data-test-id={`btn-delete-${testUid}`}
                    onClick={() => removePaymentTypeFromTable(item)}
                  />
                </RemoveButtonContainer>
              );}
          default:
            return <CellContainer children={item[paymentIndex]} />;
        }
      },
      [
        voucherPaymentTypeFoundInClinic,
        klarnaPaymentTypeFoundInClinic,
        hasPurchaseItems,
        amountOfPaymentDueAfterDiscounts,
        getInputDisableStatus,
        paymentTypeInstancesErrors,
        isFormDisabled,
        updatePaymentTypeAmount,
        updatePaymentTypeAmountWithAllIn,
        resetPaymentTypeAmount,
        handleSelectPaymentMethod,
        voucherPaymentType,
        klarnaPaymentType,
        setPayWithKlarnaDialogOpen,
        removePaymentTypeFromTable
      ]
    );

    // Effects

    useEffect(() => {
      updateInstances([...nuaVoucherInstances, ...defaultPaymentInstances]);
    }, [defaultPaymentInstances, nuaVoucherInstances, updateInstances]);

    return (
      <Accordion
        expanded={expanded && !isFormDisabled}
        onChange={() => setExpanded(state => !state)}
        disabled={isFormDisabled}
      >
        <AccordionSummary children={<Text children="Payment" />} expandIcon={<ExpandMoreIcon />} />
        <AccordionDetails>
          <Container>
            <Table<PaymentTypeTableItem>
              columns={columns}
              emptyStateProps={{title: 'Add payment type(s) to make a payment'}}
              items={paymentTypeInstanceTableItems}
              renderCell={renderCell}
              isLoading={isLoading}
            />
            <AddPaymentTypeSelect
              variant="outlined"
              disabled={!paymentTypeOptions?.length || isFormDisabled}
              focused={addPaymentTypeButtonHasFocus}
            >
              <InputLabel shrink={false} id="add-payment-type-input-label">
                Add Payment Type
              </InputLabel>
              <PaymentTypeSelect
                renderValue={() => <></>} // Prevents Select to display any selected value in the InputLabel component
                labelId="add-payment-type-select-label"
                id="add-payment-type-select"
                label="Add Payment Type"
                onOpen={() => setAddPaymentTypeButtonHasFocus(true)}
                onClose={() => setAddPaymentTypeButtonHasFocus(false)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {paymentTypeOptions?.map(type => (
                  <MenuItem
                    value={type.paymentTypeId as string}
                    onClick={() => handleSelectPaymentMethod(type)}
                    children={getPaymentTypeName(type.name as string)}
                  />
                ))}
              </PaymentTypeSelect>
            </AddPaymentTypeSelect>
          </Container>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export {klarnaPaymentTypeId}

export interface PaymentTypeTableItem extends BaseItem {
  amount?: number;
  applyFullAmount?: boolean;
  info?: string;
  name?: string;
  error?: string;
  isVoucherPlaceholder?: boolean;
  isKlarnaPlaceholder?: boolean;
}

const CellContainer = styled.div`
  margin-top: ${({theme}) => theme.spacing(1.25)};
`;

const RemoveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const columns: Column[] = [
  {id: 'name', label: 'Type'},
  {id: 'info', label: ''},
  {id: 'amount', label: 'Amount', style: {textAlign: 'right'}},
  {id: 'action', label: 'Action', style: {textAlign: 'center', width: '100px'}}
];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  td {
    vertical-align: top;
  }
  > .MuiTableContainer-root {
    overflow: unset;
  }
`;

const AddPaymentTypeSelect = styled(FormControl)`
  width: 213px;
  margin: ${({theme}) => theme.spacing(3, 0, 0)};
  legend span {
    display: none;
  }
`;

const PaymentTypeSelect = styled(Select)`
  div:first-child:focus {
    background-color: #fff;
  }
`;

const ApplyVoucherButton = styled(Button)`
  width: 215px;
  float: right;
`;
